import React from "react"
import Topbar from '../components/topbar'
import Footer from '../components/footer'
import SEO from '../components/seo'

class Privacy extends React.Component {

  componentDidMount() {
    if (window.cmAgent) {
      window.cmAgent.pageview({
        title: 'Privacy policy',
        page: '/privacy'
      });
      window.cmAgent.dispatch();
    }

    if (window && window.gtag) {
      window.gtag('config', 'G-KTYYBQVLCK', {'page_path': '/privacy'});
    }
  }

  render() {

    return <div>
      <SEO title="Privacy policy | Notifuse" />

      <Topbar />

      <div className="container">
        
        <div className="terms">
          <h1>Privacy policy</h1>

          <p>Effective: September 21, 2020.</p>

          <p>This privacy policy has been compiled to better serve those who are concerned with how their 'Personally Identifiable Information' (PII) is being used online. PII, as described in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.</p>

          <h2>1. Collected information and usage</h2>
          
          <h3>1.1 Website navigation</h3>

          <p>We use our own Captain Metrics software to monitor the traffic coming to our website. It uses cookies to anonymously collect your activity on Notifuse and those informations:</p>

          <ul>
            <li>IP address</li>
            <li>Basic browser details (url, browser version, language...)</li>
            <li>Basic platform details (screen size, OS version...)</li>
          </ul>

          <h3>1.2 Account creation</h3>

          <p>We collect the following informations to authenticate you and your business when you signup:</p>

          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Password</li>
            <li>Time zone</li>
            <li>Business details</li>
          </ul>

          <p>Notifuse also uses a cookie to keep the users logged in.</p>

          <h3>1.3 Subscription</h3>

          <p>Our payment gateway is managed by Stripe, therefore your payment details will be securely collected and won't transit on our servers. For accounting purposes we also collect the following informations:</p>

          <ul>
            <li>Business name</li>
            <li>Business ID and VAT ID</li>
            <li>Business address</li>
            <li>Business phone number</li>
          </ul>

          <h3>1.4 Platform / API</h3>

          <p>The Notifuse platform / API helps businesses sending better notifications to their users. Therefore all the data sent to Notifuse is stored and managed securely.</p>

          <ul>
            <li>First-party users data (email, telephone...)</li>
            <li>Notifications</li>
            <li>Third-party integrations credentials (Mailgun, Mailjet, Sendgrid, Postmark, Twilio, Slack etc...)</li>
            <li>Logs</li>
          </ul>

          <h3>2. Protection of your data</h3>

          <p>Notifuse is hosted in a secure datacenter (SSAE16 / ISAE 3402 Type II, ISO 27001, ISO 27017, ISO 27018, PCI DSS v3.1) and all our services are only available through SSL connection.</p>

          <p>A variety of security measures have been implemented in our services to protect our user's data from any kind of threats (XSS, CSRF etc...).</p>

          <p>All transactions are processed through a gateway provider (Stripe) and are not stored or processed on our servers.</p>

          <h3>3. Modifications</h3>

          <p>Notifuse reserves the right to modify this Privacy Policy at any time. The revisions will be effective upon Notifuse’s publication of the revised policy on the Notifuse website.</p>

          <p>Your continued use of the Notifuse website expressly indicates your acceptance of Notifuse’s then-current Privacy Policy and your consent to Notifuse’s collection of Personal Data.</p>
        </div>
      </div>

      <Footer />
    </div>
  }
}

export default Privacy
